<template>
    <b-col cols="12" style="padding: 0px !important">
        <!-- <div v-if="isLoading">
        <b-spinner class="d-block mx-auto mt-3" />
        </div> -->
        <div >
        <div class="email">
            <b-row class="bordeUp">
                <b-col cols="9" sm="5" md="4" lg="6" xl="4">
                    <div style="cursor: pointer" @click="$emit('return')">
                        <b-row>
                            <b-col cols="1" >
                                <feather-icon
                                    icon="ArrowLeftIcon"
                                    size="18"
                                    class="mr-3"
                                />
                            </b-col>
                            <b-col class="d-none d-md-block" md="8" style="padding-left:14px">
                               {{ $t('messages.back') }}
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
                <b-col cols="3" sm="3" md="4" lg="3" xl="2" offset-sm="4" offset-md="4" offset-lg="3" offset-xl="6" >
                    <div>
                        <b-row>
                            <b-col cols="12" md="2">
                                <feather-icon
                                    icon="CornerUpLeftIcon"
                                    size="18"
                                    class="mr-2"
                                    color="#d6d4d4"
                                />  
                            </b-col>
                            <b-col cols="7" sm="0" md="8" lg="10" xl="9" class="d-none d-md-block" style="color: #d6d4d4">
                               {{ $t('messages.reply') }}
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
            <div class="pt-2">
            <b-row class="borde pb-1">
                <b-col class="foto" cols="3" md="2" sm="3"  xl="1">
                <div class="ml-2 ">
                    <b-avatar
                      v-if="email.toUser != null && typeof email.toUser === 'object'"
                      size="40"
                      :src="email.toUser.avatarURL "
                      class="user-avatar float-right"
                    />
                    <b-avatar
                      v-else-if="email.fromUser != null && typeof email.fromUser === 'object'"
                      size="40"
                      :src="email.fromUser.avatarURL "
                      class="user-avatar float-right"
                    />
                    <b-avatar
                      v-else
                      size="40"
                      :src="LogoNectios"
                      class="user-avatar logoNectios float-right"
                    />
                </div>
                </b-col>
                <b-col class="nombres" xl="8" lg="7" md="7" sm="6" cols="5">
                    <h5 v-if="email.toUser" class="name html-text-ellipsis-1 ">{{email.toUser != null && typeof email.toUser === "object" ? `${email.toUser.name} ${email.toUser.surname}` : null}}</h5>
                    <h5 v-if="email.fromUser" class="name html-text-ellipsis-1 ">{{email.fromUser != null && typeof email.fromUser === "object" ? `${email.fromUser.name} ${email.fromUser.surname}` : email.fromUser}}</h5>
                    <p v-if="email.fromUser && email.fromUser != 'Nectios'" class="html-text-ellipsis-1"> {{email.fromUser != null && typeof email.fromUser === "object" && email.fromUser.headline != null ? `${email.fromUser.headline}` : $t('messages.member')}}</p>
                    <p v-if="email.toUser" class="html-text-ellipsis-1"> {{email.toUser != null && typeof email.toUser === "object" && email.toUser.headline != null ? `${email.toUser.headline}` : $t('messages.member')}}</p>
                    <p v-if="email.fromUser === 'Nectios'" class="html-text-ellipsis-1">{{ $t('messages.nectios-platform') }}</p>
                </b-col>
                <b-col class="createdAt" xl="3" lg="3" md="3" cols="3">
                <p class="text-right html-text-ellipsis-1 createdAt mr-2">
                    {{email.createdAt}}
                </p>
                </b-col>
                
            </b-row>
            <b-row>
              <b-col cols="12">
                    <h4 class="html-text-ellipsis-1 ml-2">{{email.subject}}</h4>
                  </b-col>
                <b-col cols="12">
                <div class="text-email mt-1 ml-2">
                    <p>{{email.body}}</p>
                </div>
                </b-col>
            </b-row>
            </div>
        </div>
        </div>
        <!-- <div v-else>
        <b-row class="horizontal-placeholder">
            <b-col cols="12">
            <img :src="productsPlaceholder">
            </b-col>
            <b-col cols="12">
            <p class="text-primary">
                {{ $t('available.message', { itemName: 'emails' }) }}
            </p>
            </b-col>
        </b-row>
        </div> -->
    </b-col>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BMedia, BFormFile, BMediaAside, BMediaBody, BImg, BButton, BRow, BCol, BFormInvalidFeedback, BAlert, BNav, BNavItem, BAvatar, BCard
} from 'bootstrap-vue';
import { getImageResource } from '@/@core/utils/image-utils';
import vSelect from 'vue-select';
import LogoPlaceholder from '@/assets/images/placeholders/light/icono-general-light.png';
import LogoNectios from '@/assets/images/avatars/Nectios_Logo.png';
import BannerPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import ProductsPlaceholder from '@/assets/images/placeholders/light/projects.svg';
import BackofficeContainer from '@/views/myself/components/MemberSettingContainer.vue';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import { BSpinner } from 'bootstrap-vue';

export default {
  name: 'EmailDetails',
  components: {
    BForm,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMediaAside,
    BMediaBody,
    BMedia,
    BFormFile,
    BImg,
    BButton,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormInvalidFeedback,
    BAlert,
    BNav,
    BNavItem,
    BackofficeContainer,
    SafeImg,
    BAvatar,
    BSpinner,
  },
  props:{
    email:{
        type:  Object,
        required: false,
    }
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        password: '',
        newPassword: '',
        newPassword2: ''
      },
      required,
      max,
      isStaff: false,
      send: false,
      
      date:"",
      isLoading: true,
      isActiveSend: true,
      isActiveRecibidos: false,
    };
  },
  setup() {
    const {
      getValidationState,
    } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    LogoNectios(){
      return LogoNectios
    },
    productsPlaceholder() {
      return ProductsPlaceholder;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    currentMember() {
      return this.$store.getters.loggedMember;
    },
    username() {
      return this.$route.params.username;
    },
    isSaveEnabled() {
      return this.form.name && this.form.slug && (this.form.accessibilityAux || this.form.accessibility) && (this.form.visibilityAux || this.form.visibility);
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.logoNectios{
  border-radius:0px!important;
}
.row{
  width: 100%;
}
.card-body{
  padding: 0px!important;
}
.menu{
  // border-top: 1px #d4d2d2 solid;
  border-right: 1px #d4d2d2 solid;
  min-height: 300px;
  height: 100%;
}
.foto{
  max-width: 90px;
}
.nav{
  padding-top:20px;
}
.createdAt{
    padding-right: 0px!important;
  }
.nav-item {
  font-size:1.1em;
  color: black!important ;
}
.active{
  font-weight: bold;
  border-left: 2px hsl(186deg 64% 44%)solid ;
}
.nav-item :hover{
  font-weight: bold;
}
.col{
  padding-left: 0px;
  padding-right: 0px;
}
.col-2{
  padding-right: 0px;
}
.email{
  padding-left:0px !important;
  height: 100%;
}
.bordeUp{
    border-bottom: 1px solid #f0eded;
    padding-top:5px;
    padding-bottom:5px;
}
.row{
  margin-left: 0 !important;
}
.img{
  height: 5px;
  width: 10px;
}
.col-9{
  padding-left: 0px!important
}

@media (max-width: 1300px) {
 .createdAt{
    margin-right: 10px;
  }
}

@media (max-width: 1040px) {
 .createdAt{
    margin-right: 0px;
  }
}

@media (max-width: 592px) {
 .name{
    font-size: 0.9em;
  }
  .subject{
    font-size: 0.9em;
  }
  .createdAt{
    font-size: 0.9em;
    padding: 0px!important;
    margin: 0px;
  }
  .nombres{
    padding: 0px;
  }

  .menutext{
    opacity: 0;
    font-size: 0.7em;

  }

}

@media (max-width: 592px) {
  .user-avatar{
    height: 30px!important;
    width: 30px!important;
    margin:0px!important;
    padding:0px!important;
    float:none!important;
  }
  .foto{
    
    padding:0px 10px 0px 0px!important;
    margin:0px!important;
  }
  .text-email{
    padding-left: 0px!important;
  }
}


.settings-form {
  @include media-breakpoint-up(md) {
  width: 75%;
  }
  @include media-breakpoint-up(lg) {
  width: 50%;
  }
  .avatar {
    width: 80px!important;
    height: 80px!important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] { // Class of VueCtkDateTimePicker
  color: $dark;
}
</style>
